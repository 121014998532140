
.products{
    margin-top: 6.25rem;
    text-align: center;
    margin-bottom: 1px;
    color: #25AAE1;
    font-size: 0.9375rem;

}
.dispton1{
    color: #161C2D;
    font-size: 2.25rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.875rem;
    margin-top: 5px;
    margin-left: 300px;
}
.about-cards{
    display: flex;
    flex-direction: row;
    margin-left: 250px;
    column-gap: 20px;
    margin-bottom: 100px;
}
.card-text2{
    font-size: 13px;
    opacity: 70%;
    text-align: left;
    margin-top: 10px;
    font-weight: 400;
}
.card1{
    height: 300px;
    width: 400px;
    border: none;
    background-color: white;
}
/* .ourteam-div{
    display: flex;
    flex-direction: row;
} */
.ourteam-row1{
    display: flex;
    flex-direction: row;
    column-gap: 100px;
    margin-bottom: 100px;
}
.ourteam-row2{
    display: flex;
    flex-direction: row;
    column-gap: 100px;
    
}
.team-images{
    height: 200px;
    margin-bottom: 10px;
}
.ourteam-div{
    margin-left: 300px;
    
}
.our-team{
    text-align: center;
    color: #000000;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 40px;
}
.p-h3{
    color: #161C2D;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;

}
.p-h4{
    color: #161C2D;
    font-weight: 400;
    font-size: 12px;
}
.image-dis{
    text-align: center;
    
}
.card-section{
    margin-top: 50px;
}
.ourteam-section{
    margin-bottom: 100px;
}
/* Media queeries */

@media (max-width:1200px) {
    .about-cards{
        display: flex;
        flex-direction: column;
        margin-right: 250px;
        margin-left: 250px;
    }
    .dispton1 {
        margin-left: 200px;
        font-size: 34px;
        margin-right: 230px;
    }
    .ourteam-div {
        margin-left: 200px;
    }
}
@media (max-width:992px) {
    .about-cards{
        display: flex;
        flex-direction: column;
        margin-right: 230px;
        margin-left: 230px;
    }
    .dispton1 {
        margin-left: 230px;
        font-size: 32px;
        margin-right: 230px;
    }
    .ourteam-div {
        margin-left: 100px;
    }
    .ourteam-row1, .ourteam-row2 {
        flex-direction: column;
        align-items: center;
        column-gap: 0;
        row-gap: 50px;
    }
}
@media (max-width:768px) {
    .about-cards{
        margin-right: 220px;
        margin-left: 220px;
        flex-direction: column;
        row-gap: 20px;
        column-gap: 0;
    }
    .dispton1 {
        margin-left: 210px;
        font-size: 30px;
        margin-right: 210px;
    }
    .ourteam-div {
        margin-left: 50px;
    }
    .card {
        width: 90%;
        max-width: 300px;
    }
}
@media (max-width:576px) {
    .about-cards{
        margin-right: 200px;
        margin-left: 200px;
        flex-direction: column;
        row-gap: 20px;
        column-gap: 0;
    }
    .dispton1 {
        font-size: 22px;
        margin-left: 20px;
        margin-right: 170px;
    }
    .ourteam-div {
        margin-left: 20px;
    }
    .card {
        width: 90%;
        max-width: 300px;
    }
    .our-team {
        font-size: 24px;
    }
}