
.service-section1{
   margin-left: 200px;
   align-items: center;
   margin-right: 100px;
    margin-bottom: 6.25rem;   
   
}
.icon-ic3{
    margin-left: 200px;
}
.servicemain{
   background-color: #F4F7FA ;
}
.choose-head{
    font-size: 2.25rem;
    color:#161C2D;
    margin-bottom: 50px;
    font-weight: 600;
    
}
.choose-ul{
    list-style-type: disc; /* Ensures bullet points are used */
  /* padding-left: 100px;  */
  
}
.choose-li{
    font-size: 1.1875rem;
    color: #161C2D;
    font-weight: 200;
    row-gap: .625rem;
    margin-bottom: 2.5rem;
    
}
.icon-details1{
    flex-direction: column;
    display: flex;
    margin-left: 200px;
}
.choose-dis{
    text-align: center;
}
.choose-section{
    margin-bottom: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.choose-div{
    flex-direction: row;
    display: flex;
    column-gap: 100px;
    margin-right: 100px;
}
.choose-image{

    border-radius: 22px;

}
/* Media queries */
@media (max-width:1200px) {
    .service-section{
        margin-left: 300px;
        /* align-items: center; */
        margin-right: 250px;
        
     }
    .choose-section{
        margin-bottom: 200px;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    .choose-div{
        flex-direction: column;
        display: flex;
        column-gap: 90px;
        margin-right: 80px;
        align-items: center;
    }
    .choose-image{

        border-radius: 22px;
        margin-left:180px;
    }
}
@media (max-width:992px) {
    .service-section{
        margin-left: 250px;
        /* align-items: center; */
        margin-right: 200px;
        
     }
    .choose-section{
        margin-bottom: 200px;
        text-align: center;
        display: flex;
        flex-direction: row;
    }
    .choose-div{
        flex-direction: column;
        display: flex;
        column-gap: 50px;
        margin-right: 60px;
        align-items: center;
    }
    .choose-image{

        border-radius: 22px;
        margin-left:160px;
    }
}
@media (max-width:768px) {
    .service-section{
        margin-left: 200px;
        /* align-items: center; */
        margin-right: 150px;
        
     }
    .choose-section{
        margin-bottom: 200px;
        text-align: center;
        display: flex;
        flex-direction: row;
    }
    .choose-div{
        flex-direction: column;
        display: flex;
        column-gap: 40px;
        margin-right: 40px;
        align-items: center;
    }
    .choose-image{

        border-radius: 22px;
        margin-left:140px;
    }
}
@media (max-width:576px) {
    .service-section{
        margin-left: 150px;
        /* align-items: center; */
        margin-right: 100px;
        
     }
    .choose-section{
        margin-bottom: 200px;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    .choose-div{
        flex-direction: column;
        display: flex;
        column-gap: 30px;
        margin-right: 20px;
        align-items: center;
    }
    .choose-image{

        border-radius: 22px;
        margin-left:120px;
    }
}