/* Ensure that the body and html take full height to allow sections to stretch properly */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}
 
/* Main container to take full height of viewport */
.home-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
   
/* Container for centering and max-width constraints */
.container {
    max-width: 1200px; /* Max width for larger screens */
    margin: 0 auto; /* Center horizontally */
    padding: 0 20px; /* Padding for smaller screens */
}

/* Ensures that each section takes at least the full viewport height */
.full-height {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    margin: 0; /* Remove default margin */
    padding: 20px; /* Adjust padding as needed */
}

/* Adjust styles for specific sections */
.our-products,
.our-services,
.about-us,
.testimonials,
.our-clients {
    margin-bottom: 0; /* Ensure no negative margin between sections */
}

/* Specific background colors for sections */


.our-services {
    background-color: #ffffff; /* Example background color */
}

.about-us {
    background-color: #F4F7FA; /* Example background color */
}

.testimonials {
    background-color: #ffffff; /* Example background color */
}


.footer {
    background-color: #333333; /* Example background color */
    color: #ffffff; /* Text color */
}

/* Media queries for responsive design */
@media (max-width: 1200px) {
    .container {
        padding: 0 15px; /* Adjust padding for smaller screens */
    }
}

@media (max-width: 992px) {
    .container {
        padding: 0 10px; /* Adjust padding for smaller screens */
    }
}

@media (max-width: 768px) {
    .container {
        padding: 0 5px; /* Adjust padding for smaller screens */
    }
}

@media (max-width: 576px) {
    .container {
        padding: 0 2px; /* Adjust padding for very small screens */
    }
}
