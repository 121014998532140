/* Existing CSS */

.product-images {
    display: flex;
    flex-direction: row;
    margin-left: 0; /* Removed the fixed margin-left */
    margin-right: 0; /* Removed the fixed margin-right */
    column-gap: 16px; /* Adjusted gap for better spacing */
    flex-wrap: wrap; /* Allow wrapping of items */
}

.product-name1 {
    color: #25AAE1;
    margin-left: 0; /* Adjusted margin */
    font-weight: 400;
}

.product-name {
    color: #161C2D;
    margin-left: 0; /* Adjusted margin */
    font-weight: 400;
}

.about-dis1 {
    margin-bottom: 4rem; /* Adjusted margin */
    flex-direction: column;
    display: flex;
    text-align: center;
}

.flysky-section {
    margin-top: 60px; /* Adjusted margin */
}

.video-dis1 {
    flex-direction: row;
    display: flex;
    margin-left: 0; /* Removed the fixed margin-left */
    column-gap: 30px; /* Adjusted gap */
}

ol {
    list-style-type: decimal;
    margin-left: 0; 
    text-align: left;
    margin-bottom: 20px; 
    opacity: 70%;
}

.flysky-p {
    text-align: left;
}

.flysky-img {
    height: 350px;
    width: 430px;
}

.flysky-div {
    margin-left: 0; /* Removed the fixed margin-left */
}

.dispton1 {
    font-size: 1.75rem; /* Adjusted font-size */
    font-weight: 400;
    text-align: center;
    margin-bottom: 1.875rem;
    margin-right: 0; /* Removed the fixed margin-right */
    margin-left: 0; /* Removed the fixed margin-left */
}

.description-section {
}

.description-head {
    margin-left: 0; /* Removed the fixed margin-left */
    font-size: 36px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 30px;
}

.description-p {
    margin-left: 0; /* Removed the fixed margin-left */
    opacity: 70%;
    font-size: 16px;
    margin-bottom: 50px;
}

.links {
    text-decoration: underline;
}

.features-ol li {
    list-style-type: decimal;
    margin-left: 0;
}

.feature-images {
    display: flex;
    flex-direction: row;
    margin-left: 0; /* Removed the fixed margin-left */
    column-gap: 16px; /* Adjusted gap for better spacing */
    height: 340px;
    width: 100%; /* Adjusted width */
    margin-bottom: 50px;
}

.comment-section {
    background-color: #F4F7FA;
    padding: 20px; /* Added padding for better spacing */
}

.comment-head {
    color: #161C2D;
    font-size: 20px;
    font-weight: 700;
}

.quotes1 {
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-left: 0; /* Removed the fixed margin-left */
    margin-bottom: 2rem; /* Adjusted margin-bottom */
}

.img-cards3 {
    flex-direction: row;
    display: flex;
    margin-top: .625rem;
    height: 21.875rem;
    width: 100%; /* Adjusted width */
    border: none;
}

/* Media queries */

@media (max-width: 1200px) {
    .product-images {
        display: flex;
        flex-direction: column;
        margin-left: 0; /* Adjusted margin */
        margin-right: 0; /* Adjusted margin */
        column-gap: 16px; /* Adjusted gap */
    }
    .video-dis1 {
        flex-direction: column;
        display: flex;
        margin-left: 20px; /* Adjusted margin */
        column-gap: 30px; /* Adjusted gap */
        margin-right: 20px; /* Adjusted margin */
    }
    .dispton1 {
        font-size: 1.5rem;
    }
    .feature-images {
        display: flex;
        flex-direction: column;
        width: 100%; /* Ensure images fit container */
    }
}

@media (max-width: 992px) {
    .product-images {
        display: flex;
        flex-direction: column;
        margin-left: 0; /* Adjusted margin */
        margin-right: 0; /* Adjusted margin */
        column-gap: 16px; /* Adjusted gap */
    }
    .video-dis1 {
        flex-direction: column;
        display: flex;
        margin-left: 20px; /* Adjusted margin */
        column-gap: 30px; /* Adjusted gap */
        margin-right: 20px; /* Adjusted margin */
    }
    .dispton1 {
        font-size: 1.25rem; /* Adjusted font-size */
    }
}

@media (max-width: 768px) {
    .product-images {
        display: flex;
        flex-direction: column;
        margin-left: 0; /* Adjusted margin */
        margin-right: 0; /* Adjusted margin */
        column-gap: 16px; /* Adjusted gap */
    }
    .video-dis1 {
        flex-direction: column;
        display: flex;
        margin-left: 10px; /* Adjusted margin */
        column-gap: 20px; /* Adjusted gap */
        margin-right: 10px; /* Adjusted margin */
    }
    .dispton1 {
        font-size: 1rem; 
    }
}

@media (max-width: 576px) {
    .product-images {
        display: flex;
        flex-direction: column;
        margin-left: 0; /* Adjusted margin */
        margin-right: 0; /* Adjusted margin */
        column-gap: 16px; /* Adjusted gap */
    }
    .video-dis1 {
        flex-direction: column;
        display: flex;
        margin-left: 5px; /* Adjusted margin */
        column-gap: 20px; /* Adjusted gap */
        margin-right: 5px; /* Adjusted margin */
    }
    /* .dispton1 {
        font-size: 0.875rem;
    } */
}

@media (max-width: 900px) {
    .product-images {
        display: flex;
        flex-direction: column;
        margin-left: 0; /* Adjusted margin */
        margin-right: 0; /* Adjusted margin */
    }
    .video-dis1 {
        flex-direction: column;
        display: flex;
        margin-left: 10px; /* Adjusted margin */
        column-gap: 20px; /* Adjusted gap */
        margin-right: 10px; /* Adjusted margin */
    }
    .dispton1 {
        font-size: 1.5rem; 
    }
    .feature-images {
        display: flex;
        flex-direction: column;
        width: 100%; /* Ensure images fit container */
    }
}
